<template>
  <div>
    <el-form style="padding: 20px 20px 0" :model="form" ref="form" inline>
      <el-form-item label="作者UCID">
        <el-input v-model="form.artist_ucid"></el-input>
      </el-form-item>
      <el-form-item label="作者昵称">
        <el-input v-model="form.artist_name"></el-input>
      </el-form-item>
      <el-form-item label="是否分红">
        <el-select v-model="form.is_pay">
          <el-option label="全部" :value="0"> </el-option>
          <el-option label="未分红" :value="1"> </el-option>
          <el-option label="处理中" :value="2"> </el-option>
          <el-option label="已分红" :value="3"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择月份">
        <el-date-picker v-model="form.date" type="month" placeholder="选择月"> </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="setDateUp">上一月份</el-button>
        <el-button type="primary" @click="setDateNext">下一月份</el-button>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <el-table :data="list" border stripe>
        <el-table-column label="艺术家信息" prop="artist_ucid"
          ><template slot-scope="scope">
            {{ scope.row.artist_name + `(${scope.row.artist_ucid})` }}
          </template></el-table-column
        >
        <el-table-column label="分红金额" prop="profit_amount"></el-table-column>
        <el-table-column label="分红人数" prop="partner_count"></el-table-column>
        <el-table-column label="是否分红" prop="is_pay">
          <template slot-scope="scope">
            <div>{{ scope.row.is_pay | formatPay }}</div>
          </template>
        </el-table-column>
        <el-table-column label="分红操作人" prop="operator"> </el-table-column>
        <el-table-column label="发起分红时间" prop="created_at">
          <template slot-scope="scope">
            <div>{{ scope.row.created_at | formatDate }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleBonus(scope.row)"
              v-if="scope.row.is_pay == 1"
            >
              分红
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 20px 0; text-align: right;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getArtistBonus, setPartnerBonus } from "@/api/nft.js";
import dayjs from "dayjs";

export default {
  data() {
    return {
      form: {
        artist_type: 0,
        profile_audit_status: 0,
        date: "",
      },
      list: [],
      total: 0,
      pageSize: 20,
      page: 1,
    };
  },
  computed: {
    params() {
      const { artist_ucid, artist_name, date, is_pay } = this.form;
      // console.log(date);
      let query = {
        artist_ucid: artist_ucid,
        artist_name: artist_name,
        page: this.page,
        page_size: this.pageSize,
        is_pay: is_pay,
      };
      if (date) {
        query.date = dayjs(date).unix();
      }
      return query;
    },
  },
  mounted() {
    this.fetchList();
  },
  filters: {
    formatDate: function(value) {
      return value ? dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss") : "--";
    },
    formatPay: function(value) {
      const payMap = {
        1: "未分红",
        2: "处理中",
        3: "已分红",
      };
      return payMap[value];
    },
  },
  methods: {
    async fetchList() {
      try {
        const params = { ...this.params };
        const { data } = await getArtistBonus(params);
        this.list = data.data.items || [];
        this.total = data.data.total || 0;
      } catch (error) {
        this.list = [];
        this.total = 0;
      }
    },
    onQuery() {
      this.page = 1;
      this.fetchList();
    },
    onClear() {
      this.form = {
        artist_ucid: "",
        artist_name: "",
        date: "",
      };
      this.page = 1;
      this.fetchList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    setDateUp() {
      let date = this.form.date
        ? dayjs(this.form.date)
            .subtract(1, "month")
            .startOf("month")
        : dayjs()
            .subtract(1, "month")
            .startOf("month");
      this.form.date = date.format("YYYY-MM");
    },
    setDateNext() {
      let date = this.form.date
        ? dayjs(this.form.date)
            .add(1, "month")
            .startOf("month")
        : dayjs()
            .add(1, "month")
            .startOf("month");
      this.form.date = date.format("YYYY-MM");
    },
    async handleBonus(data) {
      // setPartnerBonus
      let { date } = this.form;
      if (!date) {
        return this.$message.error("请选择月份!");
      }
      let query = {
        artist_ucid: data.artist_ucid,
        date: dayjs(date).unix(),
      };
      try {
        const { data } = await setPartnerBonus(query);
        this.$message.success("操作成功");
        this.fetchList();
      } catch (error) {}
      // console.log(data, this.params);
    },
  },
};
</script>

<style lang="less">
.html-img {
  img {
    width: 100%;
  }
}
</style>
